import React from 'react';
import {  Button, Avatar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import aboutmeimg from '../images/aboutmeimg.jpg';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
    });

const AboutMe = () => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    return (
        <React.Fragment>
            <Button 
                color="inherit" 
                uppercase={false}
                onClick={handleClickOpen}
            >
                About Me
                <InfoIcon fontSize="small"></InfoIcon>
            </Button>
            
            <Dialog 
                maxWidth="md" 
                open={open} 
                TransitionComponent={Transition}
                keepMounted
                alignSelf="center"
                onClose={handleClose} 
            >
                <DialogTitle>
                    About Me
                    <Avatar alt="DG" sx={{ width: 200, height: 200}} src={aboutmeimg} />
                </DialogTitle>
                
                <DialogContent>
                    <DialogContentText>
                        I’m Dustin, and my biggest passion is technology. I have been working with computers for over 7 years now. I have experience in semiconductor validation at AMD, tech support at Geek Squad, and software development through work and personal projects (including this website). Recently, I graduated from Texas State University with a bachelor’s degree in computer science. I’ve always been fascinated with learning all the intricate details of how computers work, from a low level in hardware and abstracted up through the software level and beyond to massive large-scale systems. My career opportunities have taught me a lot about computers by selling, supporting, fixing, building, engineering, testing, and programming them. Having seen a lot of what it takes from the hardware side of things to create bleeding edge chips that continues to push Moore’s Law, I decided to take my knowledge and skills and focus them more towards software development. Currently I am pursuing new job opportunities in full stack web development. I’m excited to continue solving complex problems with the fascinating tech that we couldn’t live without. Outside the realm of computer science, I’m a coffee aficionado, bouldering enthusiast, artist, and nature enjoyer. 
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose}>
                        <CloseIcon fontSize="small" />
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default AboutMe