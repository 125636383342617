import React from 'react';
import { Card, CardActions, CardContent, CardMedia, Button, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Link } from '@mui/material';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import useStyles from './styles';
import { useDispatch } from 'react-redux';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import CloseIcon from '@mui/icons-material/Close';

import { deletePost, likePost } from '../../../actions/posts';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
    });

const Post = ({ post, setCurrentId }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    

    return (
        <Card className={classes.card}>
            {/*<CardMedia className={classes.media} image={post.selectedFile} title={post.title}/>*/}
            <div className={classes.overlay}>
                {/*
                <Typography variant="h6" >"{post.title}"</Typography>
                <Typography variant="subtitle1">Author: {post.creator}</Typography>
                <Typography variant="subtitle2">{moment(post.createdAt).fromNow()}</Typography>
                */}
            </div>
            <div className={classes.overlay2}>
                {/*
                <Button style={{color: 'white'}} size="small" onClick={() => setCurrentId(post._id)}>
                    <MoreHorizIcon fontSize="medium" />
                </Button>
                */}
            </div>
           
            <CardContent>
                <Typography variant="h6" align="center">"{post.title}"</Typography>
                {/*<Typography variant="subtitle2">{moment(post.createdAt).fromNow()}</Typography>*/}
                <Typography variant="body2" color="textSecondary" component="p">{post.message.length > 70 ? post.message.substring(0,70)+'...' : post.message}</Typography>
            </CardContent>
            <CardActions className={classes.cardActions}>
                <Button size="small" color="primary" variant="outlined" onClick={() => dispatch(likePost(post._id))}>
                    <ThumbUpAltIcon fontSize="small" />
                    &nbsp; Likes &nbsp;
                    {post.likeCount}
                </Button>
                <Button size="small" color="primary" >

                    <React.Fragment>
                        <Button variant="outlined" onClick={handleClickOpen}>
                            <ReadMoreIcon fontSize="small" />
                            &nbsp; View...
                        </Button>
                        <Dialog 
                        maxWidth="md" 
                        open={open} 
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleClose} 
                        >
                            <DialogTitle variant="">{post.title} </DialogTitle>
                            <DialogContent>
                                <Typography variant="subtitle2">{moment(post.createdAt).fromNow()}</Typography>
                                {/*<Typography variant="subtitle2">Author: {post.creator}</Typography>
                                <Link href={post.url}>{post.url}</Link>*/}
                                <DialogContentText>{post.message}</DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="outlined" onClick={handleClose}>
                                    <CloseIcon fontSize="small" />
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </React.Fragment>

                </Button>
                {/*
                <Button size="small" color="primary" variant="outlined" onClick={() => dispatch(deletePost(post._id))}>
                    <DeleteIcon fontSize="small" />
                    Delete
                </Button>
                */}
            </CardActions>
            <div className={classes.details}>
                <Typography variant="body2" color="textSecondary">
                    {post.tags.map((tag) => `#${tag} `)}
                    </Typography>
            </div>
        </Card>
    );
}

export default Post;