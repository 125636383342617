import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  appBar: {
    borderRadius: 15,
    margin: '30px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  heading: {
    color: 'rgba(0,153,255, 1)',
  },
  image: {
    marginLeft: '15px',
  },
  mainContainer: {
    flexDirection: "column-reverse"
  }
}));