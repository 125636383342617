import React, { useState, useEffect } from 'react';
import { Container, Typography, Grow, Grid, Toolbar, Avatar, Link, AppBar } from '@mui/material';
import { useDispatch } from 'react-redux';
import { getPosts } from './actions/posts';
import useStyles from './styles';
import Posts from './components/Posts/Posts';
import Form from './components/Form/Form';
import AboutMe from './components/AboutMe';
import profile_picture from './images/profile_picture.png';
import dstndevlogo from './images/dstndevlogo.svg';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';


const App = () => {
    const [currentId, setCurrentId] = useState(null);
    const classes = useStyles();
    const dispatch = useDispatch(); // Redux hook
    document.title = 'Dustin Gordon';

    useEffect(() => {
        dispatch(getPosts());
    }, [currentId, dispatch]);

    return (
        <Container maxwidth="lg">
            
            <AppBar position="static">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                    <Avatar alt="DG" src={profile_picture} />
                    <Typography>&nbsp;&nbsp;&nbsp;</Typography>
                    <Link 
                    component="button" 
                    color="inherit" 
                    variant="subtitle1"
                    underline="hover"
                    onClick={() => {window.open('https://github.com/dustin-gordon')}}
                    >
                        GitHub&nbsp;
                        <GitHubIcon fontSize="small" />
                    </Link>
                    <Typography>&nbsp;&nbsp;&nbsp;</Typography>
                    <Link 
                    component="button" 
                    color="inherit" 
                    variant="subtitle1" 
                    underline="hover" 
                    onClick={() => {window.open('https://www.linkedin.com/in/dustin-gordon/')}}
                    >
                        LinkedIn&nbsp;
                        <LinkedInIcon align="bottom" fontSize="small" />
                    </Link>
                    <Typography>&nbsp;</Typography>
                
                    <AboutMe></AboutMe>

                    </Toolbar>
                </Container>
            </AppBar>

            <Typography 
                className={classes.heading} 
                variant="h5" 
                align="center"
                >
                    Dustin Gordon
            </Typography>
            <Grid > 
                
            </Grid>
            

            <Posts setCurrentId={setCurrentId} />
      
            {/*
            <Grow in>
                
                <Container>
                    
                    <Grid container justify="space-between" alignItems="stretch" spacing={3}>
                        <Grid item xs={12} sm={7}>
                        </Grid>
                        
                        <Grid item xs={12} sm={4}>
                             <Form currentId={currentId} setCurrentId={setCurrentId} /> 
                        </Grid>
                    </Grid>
                </Container>
            </Grow>
            */}
            
        </Container>
    );
}

export default App;