import { FETCH_ALL, CREATE, UPDATE, DELETE } from '../constants/actionTypes';

export default (posts = [], action) => {  // return fxn given params state and action
    switch(action.type) {
        case DELETE:
            return posts.filter((post) => post._id !== action.payload);
        case UPDATE: // if update or like:
            return posts.map((post) => post._id === action.payload._id ? action.payload : post);
        case FETCH_ALL:
            return action.payload;  // defined in ../actions/posts.js
        case CREATE:
            return [...posts, action.payload];
        default:
            return posts;
    }
}

// fxn is exported and instead used in ../reducers/index.js with combineReducers()