import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux'; // allows access to state store from anywhere in the app
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk'; // middleware to return function instead of action

import reducers from './reducers';

import App from './App';
import './index.css';

const store = createStore(reducers, compose(applyMiddleware(thunk)));  // data store for Redux

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>, 
    document.getElementById('root')
);  // wrap App in provider component to utilize Redux